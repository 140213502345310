import React, { useContext } from "react";
import { NativeAPI } from "src/utils/deviceUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import styles from "./BuyBox.module.scss";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { getProgramLogo, getProgramSticker } from "src/utils/programLogoUtils";
import DeviceContext from "src/contexts/DeviceContext";
import debug from "src/utils/debugUtils";

export enum SUBSCRIPTION_TYPE {
    KINDLE_UNLIMITED,
    COMICS_UNLIMITED,
}

type PropTypes = {
    asin: string;
    type: SUBSCRIPTION_TYPE;
    joinProgram?: AapiJoinProgram;
    kindleProgram?: KindleProgram;
};

const SubscriptionUpsell: React.FC<PropTypes> = ({ asin, type, joinProgram, kindleProgram }: PropTypes) => {
    const translations = useContext(TranslationsContext);
    const deviceContext = useContext(DeviceContext);
    const isKU = type === SUBSCRIPTION_TYPE.KINDLE_UNLIMITED;
    const metrics = newMetricsWithContext(isKU ? "BuyBox.KuUpsell" : "BuyBox.CuUpsell", asin);

    const subscriptionUpsellUrl = joinProgram?.url ?? (isKU
        ? `/kindle-dbs/hz/subscribe/ku?passThroughAsin=${asin}`
        : `/kindle-dbs/comics-store/unlimited?passThroughAsin=${asin}`);
    const subscriptionUpsellText = joinProgram?.data?.shortDisplayString;
    const learnMoreText = joinProgram?.data?.learnMore?.data?.displayString ?? translations.getText("learn-more");
    const upsellButtonText = joinProgram?.data?.displayString ?? translations.getText("read-for-free");

    const openLearnMore = () => {
        metrics.recordBehavioralMetric(`UpsellLearnMore.click`, 1);
        const learnMoreUrl = joinProgram?.data?.learnMore?.url ?? subscriptionUpsellUrl;
        debug.log(`openLearnMore: ${learnMoreUrl}`);
        NativeAPI.openWebPage(joinProgram?.data?.learnMore?.url ?? subscriptionUpsellUrl);
    };
    const openSubscriptionUpsellUrl = () => {
        metrics.recordBehavioralMetric(`UpsellButton.click`, 1);
        debug.log(`openSubscriptionUrl: ${subscriptionUpsellUrl}`);
        NativeAPI.openWebPage(subscriptionUpsellUrl);
    };

    const programLogo = getProgramSticker(deviceContext.theme, deviceContext.domain, kindleProgram) || getProgramLogo(isKU ? "KINDLE_UNLIMITED" : "COMICS_UNLIMITED", deviceContext.theme, deviceContext.domain);

    return (<div className={styles.subscriptionUpsell}>
        <div className={styles.programLogoContainer}>
            <img className={`${styles.programLogo} ${isKU ? "" : styles.cuLogo}`} src={programLogo?.src} alt={translations.getText(programLogo?.altKey ?? "")} />
        </div>
        <div className={styles.upsellTextContainer}>
            <div className={styles.upsellText}>
                {subscriptionUpsellText && (<>{subscriptionUpsellText + " "}</>)}
                <span className={`${styles.learnMore} ${styles.activeText}`} role="button" tabIndex={0} onClick={openLearnMore}>{learnMoreText}</span>
            </div>
        </div>
        <button className={styles.upsellButton} onClick={openSubscriptionUpsellUrl}>{upsellButtonText}</button>
    </div>);
};

export default SubscriptionUpsell;

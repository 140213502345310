import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./BuyBox.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import Spinner from "../Spinner/Spinner";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { interpolateTranslation } from "../Translations/Translations";
import debug from "src/utils/debugUtils";
import { getBbMetadata } from "src/utils/asinMetadataUtils";
import { NativeAPI } from "src/utils/deviceUtils";
import { AudibleInfo } from "./AudibleInfo";
import { AudibleConditionsOfUse } from "./AudibleConditionsOfUse";
import { PointsDetailsInfo } from "./PointsDetailsInfo";
import { CONTENT_TYPE } from "src/utils/asinUtils";
import SubscriptionUpsell, { SUBSCRIPTION_TYPE } from "./SubscriptionUpsell";
import FirstReadsUpsell from "./FirstReadsUpsell";
import AcquisitionManagerContext, { onFailure, onSuccess } from "src/contexts/AcquisitionManagerContext";
import GreatOnKindle from "./GreatOnKindle";
import { GreatOnKindleInfo } from "./GreatOnKindleInfo";
import RichContent, { RichContentExtensions } from "../RichContent/RichContent";
import { BuyOrPreorderOptions, BuyOrPreorderType } from "src/utils/ajaxUtils";
import focusHelper from "src/utils/focusHelper";
import { cleanDisplayString } from "src/utils/aapiResponseUtils";

type PropTypes = {
    data?: QuickViewAsinMetadata;
    getter?: (asin: string) => Promise<QuickViewAsinMetadata>;
    dismiss?: () => void;
    setIsInfoSheet?: (isInfoSheet: boolean) => void;
};

enum InfoSheet {
    AUDIBLE_INFO,
    AUDIBLE_CONDITIONS_OF_USE,
    POINTS_DETAILS_INFO,
    GREAT_ON_KINDLE_INFO,
    NONE,
}

enum TYP {
    PURCHASE,
    PRE_ORDER,
    NONE,
}

const maybeStopPropagation = (event: React.UIEvent) => {
    const ct = event.currentTarget;
    if (ct.scrollTop || ct.scrollHeight !== ct.clientHeight) {
        event.stopPropagation();
    }
};

const mergeParams = (baseParams: BuyOrPreorderOptions, parameters?: [AapiCtaParameter]) => {
    const item = baseParams.body.items[0];
    const body = baseParams.body;
    parameters?.forEach(paramPair => {
        if (typeof paramPair.name !== "string") { return; }
        let lastPath = paramPair.name;
        let paths = paramPair.name.split(/\./);
        let target: any = body;
        // if the path is dot segmented, navigate through and find where to write the value
        if (paths.length > 1) {
            lastPath = paths.pop() ?? ""; // target[lastPath] is where we'll eventually write the value
            if (paths[0] === "items[0]") { target = item; paths = paths.slice(1); } // no need for generic array handling
            paths.forEach(path => {
                if (typeof target[path] !== "object") { target[path] = {}; }
                target = target[path];
            });
        }
        target[lastPath] = paramPair.value;
    });
    return baseParams;
};

const openKindleStoreTermsOfUse = () => NativeAPI.openWebPage('/gp/help/customer/display.html?nodeId=201014950');

const getLocalePriceString = (locale: string, price?: CurrencyInfo) => price?.amount?.toLocaleString(locale, { style: 'currency', currency: price.currency });

const getPriceData = (locale: string, domain: string, option?: AapiBuyingOption ) => {
    if (!option?.price) { return {}; }

    const priceGroup = option.price.entity;

    return {
        savingPrice: getLocalePriceString(locale, priceGroup?.savings?.money),
        savingsPercentage: priceGroup?.savings?.percentage?.displayString,
        points: option.points?.entity?.totalAmount?.points?.amount,
        basisPriceLabel: priceGroup?.basisPrice?.label,
        basisPriceString: getLocalePriceString(locale, priceGroup?.basisPrice?.moneyValueOrRange?.value),
        priceToPayLabel: priceGroup?.priceToPay?.label,
        priceToPayString: getLocalePriceString(locale, priceGroup?.priceToPay?.moneyValueOrRange?.value),
        shouldDisplayVATIncludedMessage: false,
        shouldDisplayVATEBookPBookDifferenceMessage: false,
        shouldDisplaySellerOfRecord: false,
        sellerOfRecord: option.merchant?.entity?.merchantName,
        shouldDisplayCommissionaireMessage: true,
        isAgencyWithTax: false,
    };
};

const getKuUpsellJoinProgram = (buyingOptions?: [AapiBuyingOption]): AapiJoinProgram | undefined => {
    return buyingOptions?.find(buyingOption => buyingOption.type === "KINDLE_UNLIMITED_UPSELL")?.callToAction?.entity?.joinProgram;
};

const getCuUpsellJoinProgram = (buyingOptions?: [AapiBuyingOption]): AapiJoinProgram | undefined => {
    return buyingOptions?.find(buyingOption => buyingOption.type === "COMICS_UNLIMITED_UPSELL")?.callToAction?.entity?.joinProgram;
};

// TODO: Should this validation logic be split into the GreatOnKindle/GreatOnKindleInfo components?
const maybeValidGreatOnKindlePromo = (displayablePromotions?: [AapiDisplayablePromotion]): AapiDisplayablePromotion | undefined => {
    const promotion = displayablePromotions?.find(it => it.base?.displayStyles?.includes("GREAT_ON_KINDLE"));
    const labelContent = promotion?.longMessage?.label;
    const messageContent = promotion?.longMessage?.message;
    const savingsAmount = promotion?.combinedSavings?.savingsAmount;
    if (promotion && labelContent && messageContent && savingsAmount && (savingsAmount.amount ?? 0) > 0) {
        debug.log(`Valid GREAT_ON_KINDLE promotion`);
        return promotion;
    }
};

const maybeValidAddNarration = (whisperSyncForVoice?: WhisperSyncForVoice): WhisperSyncForVoiceBookDetails | undefined => {
    const companionBook = whisperSyncForVoice?.companionBook;
    if (companionBook?.addNarrationDisplay && companionBook.viewOnAmazonUrl && companionBook.priceIfOwnsCompanion) {
        return companionBook;
    }
    return;
};

type PrimaryCtaTextProps = {
    metadata?: QuickViewAsinMetadata;
};
const PrimaryCtaText: React.FC<PrimaryCtaTextProps> = ({ metadata }) => {
    const deviceContext = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const ctaEntity = metadata?.primaryPurchaseBuyingOption?.callToAction?.entity;
    const getCtaText = () => {
        if (ctaEntity?.oneClick) {
            return ctaEntity?.oneClick?.data?.displayString ?? translations.getText("buy-now");
        }
        if (ctaEntity?.oneClickPreorder) {
            return ctaEntity?.oneClick?.data?.displayString ??
                translations.getText(deviceContext.domain === "amazon.com" ? "preorder-with-1-click" : "preorder-now");
        }
        return "";
    }
    let cta = getCtaText();
    if (cta.indexOf("&") != -1 && cta.indexOf(";") != -1) {
        cta = cleanDisplayString(cta);
    }
    return (<>{cta}</>);
};

const OrLine: React.FC = () => {
    const translations = useContext(TranslationsContext);
    return (<div className={styles.orRow}>
        <div className={styles.fakeHrWrapper}>
            <div className={styles.fakeHr}/>
        </div>
        <div className={styles.orText}>{translations.getText("buying-options-or")}</div>
        <div className={styles.fakeHrWrapper}>
            <div className={styles.fakeHr}/>
        </div>
    </div>);
};

type MainWithContentProps = {
    extraStyle?: string;
    hasOverflowedCallback?: (hasOverflowed: boolean) => void;
};
const MainWithContent: React.FC<MainWithContentProps> = ({ extraStyle, hasOverflowedCallback, children }) => {
    const deviceContext = useContext(DeviceContext);
    const mainRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (!mainRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            const parentContainer = mainRef.current;
            if (parentContainer) {
                const hasOverflowed = parentContainer.scrollHeight > parentContainer.clientHeight;
                hasOverflowedCallback?.(hasOverflowed);
            }
        });
        resizeObserver.observe(mainRef.current);
        return () => resizeObserver.disconnect();
    }, [hasOverflowedCallback]);
    return (
        <main
            className={[styles.main, extraStyle, styles[deviceContext.theme]].join(" ")}
            onScroll={maybeStopPropagation}
            onTouchStart={maybeStopPropagation}
            onTouchMove={maybeStopPropagation}
            onTouchEnd={maybeStopPropagation}
            onTouchCancel={maybeStopPropagation}
            ref={mainRef}
        >
            {children}
        </main>
    );
};

type FooterWithButtonsProps = {
    drawBorder: boolean;
};
const FooterWithButtons: React.FC<FooterWithButtonsProps> = ({ drawBorder, children }) => {
    return (
        <footer className={[styles.footer, drawBorder ? styles.footerBorder : ""].join(" ")}>
            <div className={styles.footerButtons}>
                {children}
            </div>
        </footer>
    );
};

export const BuyBoxContent: React.FC<PropTypes> =  ({ data, getter, setIsInfoSheet, dismiss }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const acquisitionManager = useContext(AcquisitionManagerContext);
    const asin = data?.asin ?? "";
    const metrics = newMetricsWithContext("BuyBox", asin);
    const [buyButtonEnabled, setBuyButtonEnabled] = useState(false);
    const [actionInProgress, setActionInProgress] = useState(false); // Used to prevent multiple taps triggering multiple AJAX calls
    const [infoSheet, setInfoSheet] = useState(InfoSheet.NONE);
    const [thankYouPage, setThankYouPage] = useState(TYP.NONE);
    const [addNarration, setAddNarration] = useState(false);
    const [subscribeToAfrEmail, setSubscribeToAfrEmail] = useState(false);
    const [hasFetchedMetadata, setHasFetchedMetadata] = useState(false);
    const [metadata, setMetadata] = useState(data);
    const fetchFailuresCount = useRef(0);
    const fetchFailureError = useRef("");
    const [alcBuyingOption, setAlcBuyingOption] = useState<AapiBuyingOption | undefined>(data?.primaryPurchaseBuyingOption)
    const [kuUpsellJoinProgram, setKuUpsellJoinProgram] = useState<AapiJoinProgram | undefined>(getKuUpsellJoinProgram(data?.allBuyingOptions));
    const [cuUpsellJoinProgram, setCuUpsellJoinProgram] = useState<AapiJoinProgram | undefined>(getCuUpsellJoinProgram(data?.allBuyingOptions));
    const waitStartedRef = useRef(Date.now());
    const [mainHasOverflowed, setMainHasOverflowed] = useState(false);

    const actualGetter = getter || getBbMetadata;

    useEffect(() => {
        if (!hasFetchedMetadata) {
            debug.log(`Fetching buy box metadata for ${asin}`);
            setHasFetchedMetadata(true);
            actualGetter(asin).then((data) => {
                if (data.loadFailed) {
                    fetchFailureError.current = data.error || "unknown";
                    if (fetchFailuresCount.current === 0) {
                        metrics.recordOperationalMetric("fetchRetryWasTriggered", 1, fetchFailureError.current);
                    }
                    fetchFailuresCount.current += 1;
                    setHasFetchedMetadata(data.unrecoverableError === true);
                } else {
                    if (fetchFailuresCount.current > 0) {
                        metrics.recordOperationalMetric("fetchRetryWasSuccessful", 1, fetchFailureError.current);
                        metrics.recordOperationalMetric("fetchRetryWasSuccessfulAfterCount", fetchFailuresCount.current, fetchFailureError.current);
                    }
                    fetchFailuresCount.current = 0;
                }
                setMetadata((old) => {
                    return {...old, ...data };
                });
                setAlcBuyingOption(data.primaryPurchaseBuyingOption);
                setKuUpsellJoinProgram(getKuUpsellJoinProgram(data.allBuyingOptions));
                setCuUpsellJoinProgram(getCuUpsellJoinProgram(data.allBuyingOptions));
            });
        }
    }, [hasFetchedMetadata, getter, asin, metrics, actualGetter]);

    useEffect(() => {
        setBuyButtonEnabled(!!alcBuyingOption);
    }, [alcBuyingOption]);

    const hasOverflowedCallback = (hasOverflowed: boolean) => {
        setMainHasOverflowed(hasOverflowed);
    };

    const isLongWait = () => {
        const elapsed = Date.now() - waitStartedRef.current;
        return (elapsed > 2500);
    };

    const getErrorMessage = () => {
        if (!metadata?.loadFailed) {
            return;
        }
        if (metadata.unrecoverableError) {
            return (
                <div className={styles.loadFailure}>
                    <p>
                        <i>&quot;Failure is the condiment that gives success its flavor.&quot;</i>
                        <br/>
                        <b>— Truman Capote</b>
                    </p>
                    <br/>
                    {translations.getText("unrecoverable-failure-message")}
                </div>
            );
        }
        if (navigator.onLine === false) {
            return (
                <div className={styles.loadFailure}>
                    {translations.getText("no-internet-connection-title")}
                    <br/>
                    <br/>
                    {translations.getText("no-internet-connection-message")}
                </div>
            );
        }
        if (isLongWait()) {
            return (
                <div className={styles.loadFailure}>
                    {translations.getText("long-request-message")}
                </div>
            );
        }
    };

    const handleInfoSheet = (infoSheet: InfoSheet) => {
        setIsInfoSheet?.(infoSheet !== InfoSheet.NONE);
        setInfoSheet(infoSheet);
    }

    const clearInfoSheet = () => handleInfoSheet(InfoSheet.NONE);

    const toggleAddNarration = () => {
        setAddNarration(!addNarration);
    };

    const offer = metadata?.primaryPurchaseBuyingOption;
    const isPreOrder = metadata?.canPreOrder && !metadata.canBuy;
    debug.log(`isPreOrder: ${isPreOrder}`);
    debug.log(offer);

    const greatOnKindlePromo = maybeValidGreatOnKindlePromo(offer?.promotionsUnified?.entity?.displayablePromotions);
    const addNarrationPromo = maybeValidAddNarration(metadata?.whisperSyncForVoice);

    const handlePrimaryCtaClick = () => {
        if (actionInProgress) {
            return;
        }
        setActionInProgress(true);
        let params: BuyOrPreorderOptions = {
            url: (isPreOrder ? offer?.callToAction?.entity?.oneClickPreorder?.url : offer?.callToAction?.entity?.oneClick?.url) ?? "",
            type: isPreOrder ? BuyOrPreorderType.PREORDER : BuyOrPreorderType.BUY,
            isAFR: offer?.type === "LIMBER",
            body: {
                csrf: (isPreOrder ? offer?.callToAction?.entity?.oneClickPreorder?.csrf : offer?.callToAction?.entity?.oneClick?.csrf) ?? "",
                items: [{
                    action: {
                        asin,
                        actionType: isPreOrder ? "Preorder" : "Buy",
                    },
                }],
            },
        };
        params = mergeParams(params, isPreOrder ? undefined : offer?.callToAction?.entity?.oneClick?.data?.parameters);
        if (addNarration) {
            const dpUrl = addNarrationPromo?.viewOnAmazonUrl;
            const audibleAsin = dpUrl?.substring(dpUrl.length - 10);
            const audibleOurPrice = addNarrationPromo?.priceIfOwnsCompanion?.amount;
            if (audibleAsin && /^[A-Z0-9]{10}$/.test(audibleAsin) && audibleOurPrice !== undefined) {
                params.body.items[0].audibleNarration = {
                    addNarration: "WFV",
                    audibleAsin: audibleAsin,
                    audibleOurPrice: audibleOurPrice.toString(),
                };
            }
        }
        // AFR e-mail sign-up
        params.body.items[0].optional = subscribeToAfrEmail ? { botmSignupForEmail: "1" } : undefined;
        acquisitionManager.buyOrPreorder(asin, params)
            .then(onSuccess((result) => {
                debug.log(`success`);
                debug.log(result);
                setThankYouPage(isPreOrder ? TYP.PRE_ORDER : TYP.PURCHASE);
                setActionInProgress(false);
            }))
            .then(onFailure((result) => {
                debug.error(`failure for ${asin} ${isPreOrder ? "Preorder" : "Buy"}`);
                debug.error(result);
                setActionInProgress(false);
                if (result.redirectUrl) {
                    NativeAPI.openWebPage(result.redirectUrl);
                }
            }));
    };

    const handleReadNowClick = () => {
        NativeAPI.openBook(asin, metadata?.title, metadata?.authors, CONTENT_TYPE.EBOK);
        dismiss?.();
    };

    const priceData = getPriceData(deviceContext.locale, deviceContext.domain, alcBuyingOption);
    debug.log(priceData);

    const isReady = metadata?.loaded === true;

    const addNarrationRichContentExtensions: RichContentExtensions = {
        linkHandler: () => handleInfoSheet(InfoSheet.AUDIBLE_INFO),
        linkClassName: styles.activeText,
        moneyClassName: styles.addNarrationPrice,
    };

    if (infoSheet !== InfoSheet.NONE) {
        return (<>
            <MainWithContent extraStyle={styles.infoSheet} hasOverflowedCallback={hasOverflowedCallback}>
                {infoSheet === InfoSheet.AUDIBLE_CONDITIONS_OF_USE && (<AudibleConditionsOfUse />)}
                {infoSheet === InfoSheet.AUDIBLE_INFO && (<AudibleInfo />)}
                {infoSheet === InfoSheet.POINTS_DETAILS_INFO && (<PointsDetailsInfo />)}
                {infoSheet === InfoSheet.GREAT_ON_KINDLE_INFO && (<GreatOnKindleInfo asin={asin} displayablePromotion={greatOnKindlePromo} />)}
            </MainWithContent>
            <FooterWithButtons drawBorder={mainHasOverflowed}>
                <button className={styles.backButton} onClick={clearInfoSheet}>
                    <div className={styles.label}>
                        <div className={styles.backChevronIconContainer}>
                            <div className={styles.backChevronIcon} />
                        </div>
                        {translations.getText("back")}
                    </div>
                </button>
            </FooterWithButtons>
        </>);
    }

    if (thankYouPage !== TYP.NONE) {
        return (<>
            <MainWithContent extraStyle={styles.thankYouPage} hasOverflowedCallback={hasOverflowedCallback}>
                {thankYouPage === TYP.PURCHASE && (<>
                    <div className={styles.thankYouText}>
                        {translations.getText("thank-you")}
                    </div>
                    <div className={styles.titleIsNowInYourLibrary}>
                        {interpolateTranslation(
                            translations.getText("title-is-now-in-your-library"),
                            "title",
                            (<span className={styles.bookTitle}>
                                {metadata?.title ?? ""}
                            </span>)
                        )}
                    </div>
                </>)}
                {thankYouPage === TYP.PRE_ORDER && (<>
                    <div className={styles.thankYouText}>
                        {translations.getText("order-placed-thanks")}
                    </div>
                    <div className={styles.preOrderConfirmationEmail}>
                        {translations.getText("confirmation-will-be-sent-to-your-email")}
                    </div>
                </>)}
            </MainWithContent>
            <FooterWithButtons drawBorder={mainHasOverflowed}>
                {thankYouPage === TYP.PURCHASE && (<>
                    <button className={styles.readNowButton} onClick={handleReadNowClick}>
                        {translations.getText("read-now")}
                    </button>
                </>)}
                {thankYouPage === TYP.PRE_ORDER && (<>
                    <button className={styles.okButton} onClick={dismiss}>
                        {translations.getText("ok")}
                    </button>
                </>)}
            </FooterWithButtons>
        </>);
    }

    if (!deviceContext.buyBox.enabled) {
        return (<>
            <MainWithContent extraStyle={styles.notPurchaseEligible}>
                {translations.getText("this-app-does-not-support-purchasing")}
            </MainWithContent>
            <FooterWithButtons drawBorder={mainHasOverflowed}>
                <button className={styles.backButton} onClick={dismiss}>
                    {translations.getText("ok")}
                </button>
            </FooterWithButtons>
        </>);
    }

    if (!isReady) {
        return (<>
            <MainWithContent>
                <div className={styles.spinnerBox}>
                    {metadata?.unrecoverableError !== true && (<Spinner />)}
                    { getErrorMessage() }
                    {(metadata?.loadFailed) && debug.get('enableLoadFailureMessages') && (
                        <div className={styles.loadFailure}>
                            { metadata.error }
                        </div>
                    )}
                </div>
            </MainWithContent>
            <FooterWithButtons drawBorder={mainHasOverflowed}>
                <button className={styles.backButton} onClick={dismiss}>
                    {translations.getText("cancel")}
                </button>
            </FooterWithButtons>
        </>);
    }

    return (<>
    <MainWithContent hasOverflowedCallback={hasOverflowedCallback}>
        {(kuUpsellJoinProgram || debug.get("forceBuyBoxKuUpsell")) && (<>
            <SubscriptionUpsell asin={asin} type={SUBSCRIPTION_TYPE.KINDLE_UNLIMITED} joinProgram={kuUpsellJoinProgram} kindleProgram={metadata.kindleProgram} />
            <OrLine />
        </>)}

        {((cuUpsellJoinProgram && !kuUpsellJoinProgram) || debug.get("forceBuyBoxCuUpsell")) && (<>
            <SubscriptionUpsell asin={asin} type={SUBSCRIPTION_TYPE.COMICS_UNLIMITED} joinProgram={cuUpsellJoinProgram} kindleProgram={metadata.kindleProgram} />
            <OrLine />
        </>)}

        {offer && (<>
            <div className={styles.buyBox}>
                <table className={styles.buyBoxTable}>
                    {priceData.basisPriceLabel && priceData.basisPriceString && (
                        <tr>
                            <td>{priceData.basisPriceLabel}</td>
                            <td>
                                <div className={styles.basisPrice}>
                                    <s>{priceData.basisPriceString}</s>
                                </div>
                            </td>
                        </tr>
                    )}
                    {priceData.priceToPayLabel && priceData.priceToPayString && (
                        <tr>
                            <td>{priceData.priceToPayLabel}</td>
                            <td>
                                <div className={styles.kindlePrice}>
                                    {priceData.priceToPayString}
                                </div>
                                {priceData.savingPrice && priceData.basisPriceString && (
                                    <div className={styles.savings}>
                                        {translations.formatText("you-save-amount-with-percentage", { price: priceData.savingPrice, percentage: priceData.savingsPercentage })}
                                    </div>
                                )}
                                {priceData.shouldDisplayVATIncludedMessage && (
                                    <div className={styles.vatIncludedMessage}>
                                        {translations.getText("vat-included-message")}
                                    </div>
                                )}
                            </td>
                        </tr>
                    )}
                    {priceData.points && (
                        <tr>
                            <td>{translations.getText("you-earn-label")}</td>
                            <td>
                                <div className={styles.points}>
                                    {translations.formatText("points-with-units", { points: priceData.points }) + " "}
                                    <div className={`${styles.pointsDetailsLink} ${styles.activeText}`} onClick={() => handleInfoSheet(InfoSheet.POINTS_DETAILS_INFO)}>
                                        {translations.getText("points-details-link")}
                                    </div>
                                </div>

                            </td>
                        </tr>
                    )}
                    {priceData.sellerOfRecord && (
                        <tr>
                            <td>{translations.getText("sold-by-label")}</td>
                            <td>
                                <div className={styles.sellerOfRecord}>
                                    {priceData.sellerOfRecord}
                                </div>
                                {priceData.shouldDisplayCommissionaireMessage && (
                                    <div className={styles.commissionaireMessage}>
                                        {translations.getText("price-set-by-seller")}
                                    </div>
                                )}
                            </td>
                        </tr>
                    )}
                </table>
                {greatOnKindlePromo && (<GreatOnKindle asin={asin} displayablePromotion={greatOnKindlePromo} onClick={() => handleInfoSheet(InfoSheet.GREAT_ON_KINDLE_INFO)} />)}
                {addNarrationPromo?.addNarrationDisplay && (
                    <>
                        <div className={styles.addNarrationTable}>
                            <div>
                                <input className={styles.checkbox} type="checkbox" checked={addNarration} onClick={toggleAddNarration}/>
                            </div>
                            <div className={styles.addNarrationSection}>
                                <RichContent
                                    content={addNarrationPromo?.addNarrationDisplay}
                                    extensions={addNarrationRichContentExtensions}
                                />
                            </div>
                        </div>
                        {addNarration && (
                            <div className={styles.addNarrationLegalese}>
                                {interpolateTranslation(
                                    translations.getText("audible-add-narration-legalese"),
                                    "conditionsOfUseLink",
                                    (<span className={styles.activeText} onClick={() => handleInfoSheet(InfoSheet.AUDIBLE_CONDITIONS_OF_USE)}>
                                        {translations.getText("conditions-of-use")}
                                    </span>)
                                )}
                                <div>
                                    {translations.getText("sold-and-delivered-by-audible-an-amazon-company")}
                                </div>
                            </div>
                        )}
                    </>
                )}
                {isPreOrder && metadata.releaseDate && (
                    <div className={styles.preOrderAutoDeliverText}>
                        {interpolateTranslation(
                            translations.getText("preorder-auto-delivery-text"),
                            "releaseDate",
                            (<span className={styles.releaseDate}>{metadata.releaseDate?.displayString}</span>)
                        )}
                    </div>
                )}
            </div>
            {offer.type === "LIMBER" && (<FirstReadsUpsell asin={asin} buyingOption={offer} subscribeToEmailCallback={setSubscribeToAfrEmail} />)}
        </>) }

    </MainWithContent>
    <FooterWithButtons drawBorder={mainHasOverflowed}>
        {(buyButtonEnabled && !actionInProgress
            ? (<button className={styles.ctaButton} onClick={handlePrimaryCtaClick}><PrimaryCtaText metadata={metadata} /></button>)
            : (<button className={[styles.ctaButtonInProgress].join(" ")}><div>&nbsp;</div><Spinner size="1em" /><div>&nbsp;</div></button>)
        )}
        <div className={styles.oneClickLegalText}>
            <div>{interpolateTranslation(
                translations.getText("add-to-your-library-for-free-legal-warning"),
                "kindleStoreTermsOfUse",
                <span className={styles.activeText} tabIndex={0} role="button" onClick={openKindleStoreTermsOfUse}>
                    {translations.getText("kindle-store-terms-of-use")}
                </span>
            )}</div>
        </div>
    </FooterWithButtons>
    </>);
};

const BuyBox: React.FC<PropTypes> = ({ data, getter, dismiss }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const [isInfoSheet, setIsInfoSheet] = useState(false);
    const a11yInitialTarget = useRef<HTMLDivElement>(null);
    const itemContainerClassName = `${styles.itemContainer} ${styles[deviceContext.theme]}`;

    useLayoutEffect(() => {
        debug.log(`isInfoSheet: ${isInfoSheet}`);
        focusHelper.requestFocus(a11yInitialTarget.current);
    }, [isInfoSheet]);

    return (
        <div className={itemContainerClassName}>
            <div className={styles.bottomSheetChevronRow}>
                <div className={styles.chevronButton} ref={a11yInitialTarget} onClick={dismiss} tabIndex={-1} role="button" aria-label={translations.getText("dismiss")}>
                    <div className={styles.chevronIconContainer}>
                        <div className={styles.chevronIcon}/>
                    </div>
                </div>
            </div>

            <BuyBoxContent data={data} getter={getter} dismiss={dismiss} setIsInfoSheet={setIsInfoSheet} />
        </div>
    );
};

export default BuyBox;

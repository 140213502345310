import React from 'react';
import { DeviceDetailsI, SURFACE_TYPE } from 'src/utils/deviceUtils';
import { DEFAULT_THEME, Theme } from 'src/utils/themeUtils';

export type BuyBoxConfig = {
    enabled: boolean;
    enableMes: boolean;
};

export interface DeviceContextI {
    theme: Theme;
    locale: string;
    device: DeviceDetailsI;
    domain: string;
    isVisible: boolean;
    isFullUrlSupported: boolean;
    buyBox: BuyBoxConfig;
    debugFlagsTimestamp: number;
}

export function getDefaultDeviceContext(): DeviceContextI {
    return {
        theme: DEFAULT_THEME,
        locale: 'en-US',
        device: {
            surfaceType: SURFACE_TYPE.desktop,
            isIOS: false,
            isTablet: false,
            isFOS: false,
            isApp: false,
            isDesktop: false,
        },
        domain: "amazon.com",
        isVisible: false,
        isFullUrlSupported: false,
        buyBox: {
            enabled: false,
            enableMes: false,
        },
        debugFlagsTimestamp: 0,
    };
}

const DeviceContext = React.createContext<DeviceContextI>(getDefaultDeviceContext());

export default DeviceContext;

import React, { memo, useContext } from "react";
import styles from "./SponsoredLabel.module.scss";
import TranslationsContext from "src/contexts/TranslationsContext";
import DeviceContext from "src/contexts/DeviceContext";

const SponsoredLabel: React.FC = () => {
    const translations = useContext(TranslationsContext);
    const sponsoredLabel = translations.getText("sponsored-label");
    const context = useContext(DeviceContext);
    return (
        <div className={`${styles.label} ${styles[context.theme]}`} >
            {sponsoredLabel}
        </div>
    );
};

export default memo(SponsoredLabel);
